import React from 'react';
import Modal from './Modal';

const ModalController = ({handleModal, contenido}) => {
    return ( 
        <div className='modal-container'>
            <Modal close={handleModal} contenido={contenido}/>
        </div>
     );
}
 
export default ModalController;