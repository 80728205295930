import React from 'react';
import SecondaryBtn from './SecondaryBtn';

const SecondaryBtnController = ({text, itemId, callbackF}) => {
    return ( 
        <div className='secondary-btn-container'>
            <SecondaryBtn text={text} itemId={itemId} callbackF={callbackF} />
        </div>
    );
}
 
export default SecondaryBtnController;