import React from 'react';
import TopTen from './TopTen';
import SecondaryBtnController from '../SecundaryBtn/SecondaryBtnController';
import TopTenWinners from './TopTenWinners';

const TopTenController = ({dataRows, title, downloadSrc, btnText, note, parserF, wQuantity}) => {
    const firstTen = parserF(dataRows)
    .filter( row => row.ranking.v <= 10)
    const callbackF = () => {
        window.open(downloadSrc)
    };
    return ( 
        <div className='top-container'>
            {wQuantity || wQuantity === 0 ? (<TopTenWinners title={title} topTen={firstTen} wQuantity={wQuantity} />) : (<TopTen title={title} topTen={firstTen} note={note} />)}
            {btnText ? <SecondaryBtnController text={btnText} callbackF={callbackF} /> : <></>}
        </div>
     );
}
 
export default TopTenController;