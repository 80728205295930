import React, { useContext } from 'react';
import SectionTitleController from '../../components/SectionTitle/SectionTitleController';
import SubtitleController from '../../components/Subtitle/SubtitleController';
import PrimaryBtnController from '../../components/PrimaryBtn/PrimaryBtnController';
import BannerController from '../../components/Banner/BannerController';
import { Context } from '../../Context/userdata';


const Bases = () => {
    const {copa} = useContext(Context)
    const contenido = {
        title: {
            colorTitle: "#D51A00",
            colorX: "#000000",
            textContent: "BASES",
            itemId: "bases"
        },
        subtitle: {
            color: "#000000",
            text: 
            <>
                <strong>¡Bienvenido a la cancha!</strong>
                <br />
                ¡Conocé el reglamento y prepará tus mejores jugadas!
            </>
        },
        primaryBtn: {
            text: "DESCARGALO AQUÍ",
            callbackF: copa === "productividad" ? () => {
                window.open("https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Copa%20Productividad%20Conmebol%202024.pdf?alt=media&token=be185267-d014-4ab0-a067-77b19ce3e2a7")
            } : () => {
                window.open("https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Copa%20Segmentaci%C3%B3n%20Conmebol%202024.pdf?alt=media&token=c99d68d8-11d0-4821-b351-cc8d86253a0f")
            }
        }
        ,
        asset: {
            imgD: 'https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Assets4.svg?alt=media&token=5cc93b00-a696-4623-9ee1-b8cb2f59c788'
        }
    }
    return ( 
        <>
        <section className='bases'>
            <SectionTitleController {...contenido.title} />
            <SubtitleController {...contenido.subtitle} />
            <PrimaryBtnController {...contenido.primaryBtn} />
        </section>
        <BannerController {...contenido.asset} />
        </>
     );
}
 
export default Bases;