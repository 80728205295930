import React, { useContext } from 'react';
import SectionTitleController from '../../components/SectionTitle/SectionTitleController';
import SubtitleController from '../../components/Subtitle/SubtitleController';
import TopTenController from '../../components/TopTen/TopTenController';
import { Context } from '../../Context/userdata';

const Ranking = () => {
    const { 
        isListLoaded1, 
        isListLoaded2, 
        isListLoaded3,
        dataRowsLists1,
        dataRowsLists2,
        dataRowsLists3,
        isdownloadLoaded1,
        isdownloadLoaded2,
        isdownloadLoaded3,
        downloadSrc1,
        downloadSrc2,
        downloadSrc3,
        parseFContext,
        copa
    } = useContext(Context)
    const contenido = {
        title: {
            colorTitle: "#D51A00",
            colorX: "#000000",
            textContent: "RANKING",
            itemId: "ranking"
        },
        subtitle: {
            color: "#ffffff",
            text: 
            <>
                <strong>¡Consultá tu posición en la tabla!</strong>
                <br />
                Descubrí si sos el goleador del torneo. ¡Cada jugada cuenta!
            </>
        },
        top1: {
            title: "GERENTE COMERCIAL",
            downloadSrc: downloadSrc1,
            btnText: "DESCARGAR RANKING GERENTES",
            parserF: parseFContext('gerentes'),
            wQuantity: 0
        },
        top2: {
            title: "GESTOR COMERCIAL",
            downloadSrc: downloadSrc2,
            btnText: "DESCARGAR RANKING GESTORES",
            parserF: parseFContext('gestores'),
            wQuantity: 0
        },
        top3: {
            title: "ADMINISTRATIVO COMERCIAL",
            downloadSrc: downloadSrc3,
            btnText: "DESCARGAR RANKING ADMINISTRATIVOS",
            note: "*Es condición no decrecer en el total de la cartera de tu código vs junio 2024.",
            parserF: parseFContext('administrativos'),
            wQuantity: 1
        }
    }
    const contenidoS = {
        title: {
            colorTitle: "#D51A00",
            colorX: "#ffffff",
            textContent: "RANKING",
            itemId: "ranking"
        },
        subtitle: {
            color: "#ffffff",
            text: 
            <>
                <strong>¡Si pasaste a FASE 2<br /> consultá tu posición en la tabla!</strong>
            </>
        },
        top1: {
            title: "DELEGADOS",
            downloadSrc: downloadSrc1,
            btnText: "DESCARGAR RANKING DELEGADOS",
            parserF: parseFContext('delegados'),
            wQuantity: 2
        },
        top2: {
            title: "REPRESENTACIÓN",
            downloadSrc: downloadSrc2,
            btnText: "DESCARGAR RANKING REPRESENTACIÓN",
            parserF: parseFContext('representacion'),
            wQuantity: 2
        },
        top3: {
            title: "PAS TRADICIONAL",
            downloadSrc: downloadSrc3,
            btnText: "DESCARGAR RANKING PAS TRADICIONAL",
            parserF: parseFContext('pas'),
            wQuantity: 2
        }
    }
    
    return ( 
        <section className='ranking'>
            {isListLoaded1 && isListLoaded2 && isListLoaded3 && isdownloadLoaded1 && isdownloadLoaded2 && isdownloadLoaded3 ? copa === "productividad" ? (
                <>
                <SectionTitleController {...contenido.title} />
                <SubtitleController {...contenido.subtitle} />
                <TopTenController {...contenido.top1} dataRows={dataRowsLists1} />
                <TopTenController {...contenido.top2} dataRows={dataRowsLists2} />
                <TopTenController {...contenido.top3} dataRows={dataRowsLists3} />
                </>
            ) : (<>
                <SectionTitleController {...contenidoS.title} />
                <SubtitleController {...contenidoS.subtitle} />
                <TopTenController {...contenidoS.top1} dataRows={dataRowsLists1} />
                <TopTenController {...contenidoS.top2} dataRows={dataRowsLists2} />
                <TopTenController {...contenidoS.top3} dataRows={dataRowsLists3} />
            </>) : (<></>)}
        </section>
     );
}
 
export default Ranking;