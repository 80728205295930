import React from 'react';
import Banner from './Banner';

const BannerController = ({imgD, imgDL, imgM, alt}) => {
    return ( 
        <section className="banner">
            <Banner imgD={imgD} imgDL={imgDL} imgM={imgM} alt={alt} />
        </section> 
    );
}
 
export default BannerController;