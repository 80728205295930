import React from 'react';
import PrimaryBtnController from '../PrimaryBtn/PrimaryBtnController';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, database } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

const Login = ({copa}) => {
    const navigation = useNavigate()
    const contenido = {
        btn: {
            text: "INGRESAR",
            itemId: "login-btn",
            callbackF: (e) => {
                e.preventDefault()
                const email = document.querySelector("#input-email").value
                const password = document.querySelector("#input-pass").value

                signInWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    const docRef = doc(database, "usuarios", user.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        if (docSnap.data().isFirst) {
                            navigation(`/change-password/${copa}`)
                        }
                    }
                })
                .catch(err => {
                    const errorCode = err.code;
                    const errorMessage = err.message;

                    alert(`Error: Su E-mail o Contraseña son incorrectos`)

                    // console.log(errorMessage)
                })
                
            }
        }
    }
    return ( 
        <form className='login'>
            <p>
                Ingresá tus datos
                <br />
                <strong>para iniciar sesión</strong>
            </p>
            <input id='input-email' type="email" placeholder='E-mail' />
            <input id='input-pass' type="password" placeholder='Contraseña' />
            <PrimaryBtnController {...contenido.btn} />
            <a href={`/reset-password/${copa}`}>¿Olvidaste tu contraseña?</a>
        </form>
    );
}
 
export default Login;