import React from 'react';
import PrimaryBtnController from '../PrimaryBtn/PrimaryBtnController';
import { updatePassword } from 'firebase/auth';
import { auth, database } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';

const ChangePassword = () => {
    const navigate = useNavigate()
    const contenido = {
        btn: {
            text: "Cambiar contraseña",
            itemId: "reset-btn",
            callbackF: (e) => {
                e.preventDefault()
                const pass = document.querySelector("#input-pass").value
                const passCheck = document.querySelector("#input-pass-check").value

                if (pass === passCheck) {
                    updatePassword(auth.currentUser, pass)
                    .then(async () => {
                        const docRef = doc(database, "usuarios", auth.currentUser.uid)
                        await updateDoc(docRef, {isFirst: false})
                        alert('Contraseña cambiada correctamente')
                        navigate("/")
                    }).catch(err => {
                        if (err.code === "auth/weak-password") {
                            alert("La contraseña es muy débil.")
                        } else {
                            alert("Algo salio mal.")
                        }
                    })
                } else {
                    alert("Las contraseñas no coinciden.")
                }
                
            }
        }
    }
    return ( 
        <form className='change-password'>
            <p>
                <strong>Ingresá tu nueva contraseña</strong>
            </p>
            <input id='input-pass' type="password" placeholder='Nueva contraseña' />
            <input id='input-pass-check' type="password" placeholder='Repetir nueva contraseña' />
            <PrimaryBtnController {...contenido.btn} />
            <a href={"/"}>Continuar sin cambiar</a>
        </form>
    );
}
 
export default ChangePassword;