import React, { useState } from 'react';
import Legal from '../Legal/Legal';
import logo from '../../imgs/logo.svg';
import BannerController from '../../components/Banner/BannerController';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const contenido = {
        asset1: {
            imgD: 'https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Assets3.svg?alt=media&token=86eca758-3073-4864-8969-c0b261d5c22f'
        },
        asset2: {
            imgD: 'https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Assets1.svg?alt=media&token=1691bd95-14bb-4c7d-919a-4c421a576393'
        },
    }
    const location = useLocation()
    const [isUserLogged, setIsUserLogged] = useState(false);
    onAuthStateChanged(auth, user => {
        if (user) {
            setIsUserLogged(true)
        } else {
            setIsUserLogged(false)
        }
    })

    return ( 
        <footer className="footer">
            {isUserLogged && location.pathname !== '/change-password/productividad' && location.pathname !== '/change-password/segmentacion'? <BannerController {...contenido.asset1} /> : <></>}

            <div className='footer-logo-container'>
                <a className='footer-logo' href="https://www.mapfre.com.ar/" target="_blank" rel="noopener noreferrer"><img src={logo} alt="MAPFRE" /></a>
            </div>
            <BannerController {...contenido.asset2} />
            <Legal />
        </footer>
     );
}
 
export default Footer;