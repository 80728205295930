import React, { useState } from 'react';

const MyData = ({ data }) => {
    const [dataAux, setDataAux] = useState({...data});
    return ( 
        <>
            <tr className='my-data-item'>
                {Object.keys(dataAux).map( (keyName, key) => keyName !== "mail" ? (
                    <td style={{fontWeight: "bold", textTransform: "capitalize"}} key={key}>{dataAux[keyName].label}</td>
                ): <></>)}
            </tr>
            <tr className='my-data-item'>
                {Object.keys(dataAux).map( (keyName, key) => keyName !== "mail" ? (
                    <td key={key}>{dataAux[keyName].v}</td>
                ): <></>)}
            </tr>
        </>
     );
}
 
export default MyData;