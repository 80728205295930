import React, { useEffect, useState } from 'react';
import NavbarHeader from './NavbarHeader';
import NavbarView from './NavbarView';
import BannerController from '../Banner/BannerController';
import imgDp from '../../imgs/banners/Banner_Desktop_Productividad-texto.jpg'
import imgDLp from '../../imgs/banners/Banner_Desktop L_Productividad-texto.jpg'
import imgMp from '../../imgs/banners/Banner_Mobile_Productividad-texto.jpg'
import imgDs from '../../imgs/banners/Banner_Desktop_Segmentacion-texto.jpg'
import imgDLs from '../../imgs/banners/Banner_Desktop L_Segmentacion-texto.jpg'
import imgMs from '../../imgs/banners/Banner_Mobile_Segmentacion-texto.jpg'
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from '../../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const contenidoS = {
        hero: {
            imgD: imgDs,
            imgDL: imgDLs,
            imgM: imgMs,
        }
    }
    const contenidoP = {
        hero: {
            imgD: imgDp,
            imgDL: imgDLp,
            imgM: imgMp,
        }
    }
    const [isUserLogged, setIsUserLogged] = useState(false)
    const [role, setRole] = useState("");
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const location = useLocation()

    onAuthStateChanged( auth, user => {
        if (user) {
            setIsUserLogged(true)
            } else {
            setIsUserLogged(false)
        }
    })
    useEffect(() => {
        if (location.pathname === '/change-password/productividad' || location.pathname === '/change-password/segmentacion') {
            setIsChangePassword(true)
        } else {
            setIsChangePassword(false)
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isUserLogged) {
            const docRef = doc(database, "usuarios", auth.currentUser.uid)
            getDoc(docRef)
            .then(snap => {
                if (snap.exists() && snap.data().role) {
                    const data = snap.data()
                    setRole(data.role);
                }
            })
            .then(() => setIsDataLoaded(true))
            .catch(err => console.log(err))
        }
    }, [isUserLogged, role]);
    return ( 
        <>
            <NavbarHeader isUserLogged={isUserLogged} />
            {isUserLogged && !isChangePassword ? (<>
                <NavbarView />
                {isDataLoaded ? 
                    ((role === "gerentes" || role === "gestores" || role === "administrativos") ? 
                        <BannerController {...contenidoP.hero} /> : 
                        ((role === "delegados" || role === "representacion" || role === "pas") ? 
                            <BannerController {...contenidoS.hero} /> : 
                        <></>)) : 
                    <></> 
                }
            </>) : (<></>)}
        </>
    );
}
 
export default Navbar;