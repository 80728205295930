import React from 'react';
import MyData from './MyData';
import { auth } from '../../services/firebase';

const MyDataController = ({dataRows, parserF}) => {
    const myData = parserF(dataRows)
    .find(dato => dato.mail.v.toLowerCase() === 
    auth.currentUser.email.toLowerCase()
)
    return ( 
        <div className='my-data-container'>
            <table className='my-data'>
                <tbody>
                    {myData? <MyData data={myData} /> : <p style={{color: "#fff", textAlign: "center", fontSize: "1.2rem"}}>No encontramos tu usuario en la lista.</p>}
                    
                </tbody>
            </table>
        </div>
    );
}
 
export default MyDataController;