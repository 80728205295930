import React from 'react';
import BannerController from '../Banner/BannerController';
import imgDp from '../../imgs/banners/Banner_Desktop_Productividad-texto.jpg'
import imgDLp from '../../imgs/banners/Banner_Desktop L_Productividad-texto.jpg'
import imgDs from '../../imgs/banners/Banner_Desktop_Segmentacion-texto.jpg'
import imgDLs from '../../imgs/banners/Banner_Desktop L_Segmentacion-texto.jpg'
import imgM from '../../imgs/banners/Banner_Mobile.jpg'
import ChangePassword from './ChangePassword';
import { useParams } from 'react-router-dom';

const ChangePasswordController = () => {
    const params = useParams()
    const contenido = {
        hero: {
            imgD: params.copa === "productividad"? imgDp : imgDs,
            imgDL: params.copa === "productividad"? imgDLp : imgDLs,
            imgM: imgM,
        },
    }
    return ( 
        <div className='change-password-component'>
            <BannerController {...contenido.hero} />
            <div className='change-password-container'>
                <ChangePassword />
            </div>
        </div>
     );
}
 
export default ChangePasswordController;