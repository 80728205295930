import React, { useContext } from 'react';
import SectionTitleController from '../../components/SectionTitle/SectionTitleController';
import SubtitleController from '../../components/Subtitle/SubtitleController';
import MyDataController from '../../components/MyData/MyDataController';
import BannerController from '../../components/Banner/BannerController';
import { Context } from '../../Context/userdata';

const TusDatos = () => {
    const { 
        isDataLoaded,
        isListLoaded1,
        isListLoaded2,
        isListLoaded3,
        dataRowsLists1,
        dataRowsLists2,
        dataRowsLists3,
        parseFContext,
        role,
        copa,
     } = useContext(Context)
    const datos = 
    role === "gerentes" || role === "delegados" ? dataRowsLists1 : role === "gestores" || role === "representacion" ? 
    dataRowsLists2 : dataRowsLists3;
    let contenido = {
        title: {
            colorTitle: "#000000",
            colorX: "#ffffff",
            textContent: "TUS DATOS",
        },
        subtitle: {
            color: "#ffffff",
            text: role === "administrativos" ? 
            <>Administrativos Comerciales</> :
            <>
                Gerentes y Gestores
            </>
        },
        myData: {
            dataRows: datos,
            parserF: parseFContext(role),
        },
        asset: {
            imgD: 'https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Assets1.svg?alt=media&token=1691bd95-14bb-4c7d-919a-4c421a576393'
        }
    }
    return ( 
        <section className='tus-datos-container'>
            {isDataLoaded &&
            isListLoaded1 &&
            isListLoaded2 &&
            isListLoaded3 ? (<>
                <div className='tus-datos'>
                    <SectionTitleController {...contenido.title} />
                    {copa === "productividad" ? <SubtitleController {...contenido.subtitle} /> : <></>}
                    <MyDataController {...contenido.myData} />
                </div>
                <BannerController {...contenido.asset} />
            </>) : <></>}
        </section>
     );
}
 
export default TusDatos;