import React from 'react';
import SpotlightText from './SpotlightText';

const SpotlightTextController = ({text}) => {
    return ( 
        <div className='spotlight-text-container'>
            <SpotlightText text={text} />
        </div>
     );
}
 
export default SpotlightTextController;