import React, { useContext } from 'react';
import SectionTitleController from '../../components/SectionTitle/SectionTitleController';
import SubtitleController from '../../components/Subtitle/SubtitleController';
import SpotlightTextController from '../../components/SpotlightText/SpotlightTextController';
import PrizeController from '../../components/Prize/PrizeController';
import BannerController from '../../components/Banner/BannerController';
import { Context } from '../../Context/userdata';

const Premios = () => {
    const { copa } = useContext(Context)
    const contenido = {
        title: {
            colorTitle: "#000000",
            colorX: "#D51A00",
            textContent: "PREMIOS",
            itemId: "premios"
        },
        subtitle: {
            color: "#000000",
            text: <strong>¿Te imaginás estar en la final de la CONMEBOL Libertadores?</strong>
        },
        spotlight: {
            text: copa === "productividad" ? (
                <>
                    ¡Te presentamos al ganador de la COPA PRODUCTIVIDAD!
                </>
            ) : (
                <>
                    ¡Te presentamos a los ganadores de la COPA SEGMENTACIÓN!
                </>
            )
        },
        prizeContent: {
            prizes: copa === "productividad" ? [
                {
                    target: <>ADM. COMERCIAL: <br />SERENO, Lucas Gabriel</>,
                    detail: <><strong>1 ENTRADA</strong><br />FINAL CONMEBOL LIBERTADORES</>,
                }
            ] : [
                {
                    target: <>DELEGADOS<br /><span className='anotacion'>STRATTA FACUNDO <br /> BLANCO DAVID SATURNINO</span></>,
                    detail: <><strong>1 ENTRADA POR GANADOR</strong><br />FINAL CONMEBOL LIBERTADORES</>,
                },
                {
                    target: <>REPRESENTACIÓN<br /><span className='anotacion'>MOGENSEN GABRIELA <br /> MIRANDA MATIAS NELSON MARTIN</span></>,
                    detail: <><strong>1 ENTRADA POR GANADOR</strong><br />FINAL CONMEBOL LIBERTADORES</>,
                },
                {
                    target: <>PAS TRADICIONAL<br /><span className='anotacion'>MARULLO VERONICA ESTHER <br /> ANGEL ANALIA INES</span></>,
                    detail: <><strong>1 ENTRADA POR GANADOR</strong><br />FINAL CONMEBOL LIBERTADORES</>,
                }
            ]
        },
        asset: {
            imgD: 'https://firebasestorage.googleapis.com/v0/b/la-copa-mapfre.appspot.com/o/Assets2.1.svg?alt=media&token=a4a4e1ba-f0f4-4421-9603-c507ae32de48'
        }
    }
    return ( 
        <>
        <BannerController {...contenido.asset} />
        <section className='premios'>
            <SectionTitleController {...contenido.title} />
            <SubtitleController {...contenido.subtitle} />
            <SpotlightTextController {...contenido.spotlight} />
            <PrizeController {...contenido.prizeContent} />
        </section>
        </>
     );
}
 
export default Premios;