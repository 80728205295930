import React from 'react';
import XIcon from './XIcon';

const XIconController = ({color}) => {
    return ( 
        <div className='x-icon' style={{color: color}}>
            <XIcon />
        </div>
    );
}
 
export default XIconController;