import React from 'react';
import PrimaryBtnController from '../PrimaryBtn/PrimaryBtnController';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../services/firebase';

const ResetPassword = ({copa, handleModal}) => {
    
    const contenido = {
        btn: {
            text: "ENVIAR",
            itemId: "login-btn",
            callbackF: (e) => {
                e.preventDefault()
                const email = document.querySelector("#input-email").value

                sendPasswordResetEmail(auth, email)
                .then(() => {
                    handleModal()
                })
                .catch(err => {
                    const errorCode = err.code;
                    const errorMessage = err.message;

                    alert(`Error: Su E-mail o Contraseña son incorrectos`)

                    // console.log(errorMessage)
                })
                
            }
        }
    }
    return ( 
        <form className='reset-password'>
            <p>
                Ingresá tu email
                <br />
                <strong>para reiniciar tu contraseña</strong>
            </p>
            <input id='input-email' type="email" placeholder='E-mail' />
            <PrimaryBtnController {...contenido.btn} />
            <a href={`/login/${copa}`}>Volver al Log In</a>
        </form>
    );
}
 
export default ResetPassword;