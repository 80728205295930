import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { BrowserRouter } from 'react-router-dom';
import Main from './pages/Main/Main';

function App() {
  

  return (
    <div className="App">
        <BrowserRouter>
          <Navbar />
          <Main />
          <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
