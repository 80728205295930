import React from 'react';
import star from '../../imgs/icono-estrella.svg'

const Prize = ({target, detail}) => {
    return ( 
        <li className='prize'>
            <div className='prize-box1'>
                <img className='prize-img' src={star} alt="" />
                <h3 className='prize-target'>{target}</h3>
            </div>
            <p className='prize-detail'>{detail}</p>
        </li>
     );
}
 
export default Prize;