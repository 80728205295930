import React from 'react';
import logo from '../../imgs/logo.svg'
import SimpleBtnController from '../SimpleBtn/SimpleBtnController';
import { auth } from '../../services/firebase';
import { signOut } from 'firebase/auth';

const NavbarHeader = ({isUserLogged}) => {
    const btnContent = {
        text: "Cerrar Sesión",
        itemId: "logout",
        callbackF: (e) => {
            e.preventDefault()
            signOut(auth)
            .then(() => console.log(auth.currentUser))
            .catch(err => console.log(err))
        }
    }
    return ( 
        <header className='header'>
            <div className='header-container'>
                {isUserLogged ? <SimpleBtnController {...btnContent} /> : <></>}
                <a className='header-logo' href="https://www.mapfre.com.ar/" target="_blank" rel="noopener noreferrer"><img src={logo} alt="MAPFRE" /></a> 
            </div>
        </header> 
    );
}
 
export default NavbarHeader;