import React, { useState } from 'react';
import BannerController from '../Banner/BannerController';
import imgDp from '../../imgs/banners/Banner_Desktop_Productividad-texto.jpg'
import imgDLp from '../../imgs/banners/Banner_Desktop L_Productividad-texto.jpg'
import imgDs from '../../imgs/banners/Banner_Desktop_Segmentacion-texto.jpg'
import imgDLs from '../../imgs/banners/Banner_Desktop L_Segmentacion-texto.jpg'
import imgM from '../../imgs/banners/Banner_Mobile.jpg'
import ResetPassword from './ResetPassword';
import ModalController from '../Modal/ModalController';
import { useParams } from 'react-router-dom';

const ResetPasswordController = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

const params = useParams()

    const handleModal = () => {
        setIsModalOpen(!isModalOpen)
    }
    const contenido = {
        hero: {
            imgD: params.copa === "productividad"? imgDp : imgDs,
            imgDL: params.copa === "productividad"? imgDLp : imgDLs,
            imgM: imgM,
        },
        modal: {
            handleModal: handleModal,
            contenido: <>
            Te enviamos un mail con indicaciones para que generes tu nueva contraseña.
            <br />
            <br />
            <strong>Revisá tu correo electrónico para actualizar tu clave.</strong>
            </>
        }
    }
    return ( 
        <div className='reset-password-component'>
            <BannerController {...contenido.hero} />
            <div className='reset-password-container'>
                <ResetPassword copa={params.copa} handleModal={handleModal} />
            </div>
            {isModalOpen? <ModalController {...contenido.modal} /> : <></>}
        </div>
     );
}
 
export default ResetPasswordController;