import React from 'react';
import SimpleBtn from './SimpleBtn';

const SimpleBtnController = ({text, callbackF, itemId}) => {
    return ( 
        <div className='simple-btn'>
            <SimpleBtn text={text} callbackF={callbackF} itemId={itemId} />
        </div>
    );
}
 
export default SimpleBtnController;