import React from 'react';
import PrimaryBtn from './PrimaryBtn';

const PrimaryBtnController = ({text, itemId, callbackF}) => {
    return ( 
        <div className={itemId ? `primary-btn-container ${itemId}` : "primary-btn-container"}>
            <PrimaryBtn text={text} itemId={itemId} callbackF={callbackF} />
        </div>
    );
}
 
export default PrimaryBtnController;