import React, { useContext } from 'react';
import { Context } from '../../Context/userdata';

const TopTen = ({topTen, title, note}) => {

    const {copa} = useContext(Context)
    return ( 
        <div className='top-table'>
            <h3 className='top-table-title'>{title}</h3>
            {note ? <span className='top-table-note'>{note}</span> : <></>}
            <table className='top-table-table' border="0">
                {copa === "productividad" ? (
                    <tbody>
                        <tr className='table-headers'>
                            <td>PUESTO</td>
                            <td>NOMBRE Y APELLIDO</td>
                            <td>REGIÓN</td>
                            <td>OC</td>
                        </tr>
                        { topTen ? topTen.length > 0 ? topTen.map((top, key) => (
                            <tr key={key} className='table-item'>
                                <td style={{fontWeight: "bold"}}>{top.ranking.v}</td>
                                <td>{top.nombre.v}</td>
                                <td>{top.region.v}</td>
                                <td>{top.oficina.v}</td>
                            </tr>
                        )) : <tr className='table-item'>
                        <td>Sin Datos</td>
                        <td>&mdash;</td>
                        <td>&mdash;</td>
                        <td>&mdash;</td>
                        </tr> : <></>}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className='table-headers'>
                            <td>PUESTO</td>
                            <td>NOMBRE Y APELLIDO</td>
                            <td>AGRUPADOR COMERCIAL</td>
                        </tr>
                        { topTen ? topTen.length > 0 ? topTen.map((top, key) => (
                            <tr key={key} className='table-item'>
                                <td style={{fontWeight: "bold"}}>{top.ranking.v}</td>
                                <td>{top.agrupacion.v}</td>
                                <td>{top.agrupacionCod.v}</td>
                            </tr>
                        )) : <tr className='table-item'>
                            <td>&mdash;</td>
                            <td>&mdash;</td>
                            <td>&mdash;</td>
                            </tr> : <></>}
                    </tbody>
                )}
            </table>
        </div>
     );
}
 
export default TopTen;