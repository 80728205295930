import React from 'react';
import Prize from './Prize';

const PrizeController = ({prizes}) => {
    return ( 
        <div className='prize-container'>
            <ul className='prize-gallery'>
                {prizes.map((prize, key) => (
                    <Prize key={key} {...prize} />
                ))}
            </ul>
        </div>
     );
}
 
export default PrizeController;