import React from 'react';
import NavbarItem from './NavbarItem';

const NavbarView = () => {
    const navigation = [
        {
            link: 'bases',
            text: 'Bases'
        },
        {
            link: 'ranking',
            text: 'Ranking'
        },
        {
            link: 'premios',
            text: 'Premios'
        }
    ];
    return ( 
        <nav className='navigation'>
            <ul className='navigation-list'>
                {navigation.map( (navLink, key) => (
                    <NavbarItem key={key} {...navLink} />
                ))}
            </ul>
        </nav>
    );
}
 
export default NavbarView;