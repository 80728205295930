import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { auth, database } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const Context = createContext();

export const DataProvider = ({children}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isListLoaded1, setIsListLoaded1] = useState(false);
    const [isListLoaded2, setIsListLoaded2] = useState(false);
    const [isListLoaded3, setIsListLoaded3] = useState(false);
    const [dataRowsLists1, setDataRowsLists1] = useState({});
    const [dataRowsLists2, setDataRowsLists2] = useState({});
    const [dataRowsLists3, setDataRowsLists3] = useState({});
    const [isdownloadLoaded1, setIsDownloadLoaded1] = useState(false);
    const [isdownloadLoaded2, setIsDownloadLoaded2] = useState(false);
    const [isdownloadLoaded3, setIsDownloadLoaded3] = useState(false);
    const [downloadSrc1, setDownloadSrc1] = useState('');
    const [downloadSrc2, setDownloadSrc2] = useState('');
    const [downloadSrc3, setDownloadSrc3] = useState('');
    const [role, setRole] = useState('');
    const [copa, setCopa] = useState('');

    const getFromSheets = (thiscopa, thisrole, setData, setIsLoaded) => {
        let gid = 0;
        switch (thisrole) {
            case 'gerentes':
                gid = "0"
                break;
            case 'gestores':
                gid = "1580766417"
                break;
            case 'administrativos':
                gid = "149713631"
                break;
            case 'delegados':
                gid = "0"
                break;
            case 'representacion':
                gid = "1375703423"
                break;
            case 'pas':
                gid = "766805053"
                break;
        
            default:
                break;
        }
        const id = thiscopa === 'productividad' ? process.env.REACT_APP_PRODUCTIVIDAD_SPREADSHEET_ID : process.env.REACT_APP_SEGMENTACION_SPREADSHEET_ID;
        const url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid+"#gid="+gid;

        fetch(url)
            .then(response => response.text())
            .then(data => data.slice(47, -2))
            .then(jsonData => {
                setData(jsonData)
            })
            .then(() => setIsLoaded(true))
            .catch(err => {
                console.log(err)
            });
    }
    const getDownloadSrc = (thiscopa, thisrole, setData, setIsLoaded) => {
        let gid = 0;
        switch (thisrole) {
            case 'gerentes':
                gid = "0"
                break;
            case 'gestores':
                gid = "1580766417"
                break;
            case 'administrativos':
                gid = "149713631"
                break;
            case 'delegados':
                gid = "0"
                break;
            case 'representacion':
                gid = "1375703423"
                break;
            case 'pas':
                gid = "766805053"
                break;
        
            default:
                break;
        }
        const id = thiscopa === 'productividad' ? process.env.REACT_APP_PRODUCTIVIDAD_SPREADSHEET_ID : process.env.REACT_APP_SEGMENTACION_SPREADSHEET_ID;
        const url = 'https://docs.google.com/spreadsheets/d/'+id+'/export?format=pdf&id='+id+'&gid='+gid+"#gid="+gid;

        setData(url)
        setIsLoaded(true)
    }
    const parseFContext = useCallback((role) => {
        let parseFAUsar;
        switch (role) {
            case "gerentes":
                parseFAUsar = (jsonData) => {
                    const jsonTable = JSON.parse(jsonData).table
                    const parsedData = []
                    
                    const dayjs = require('dayjs');
                    jsonTable.rows.forEach( (row, i) => {
                        if (i > 1) {
                            const meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
                            const newRowData = {
                                region: {
                                    label: 'Región',
                                    v: row.c[0].v,
                                },
                                oficina: {
                                    label: 'Oficina Comercial',
                                    v: row.c[1].v,
                                },
                                nombre: {
                                    label: 'Gerente Comercial',
                                    v: row.c[2].v,
                                },
                                mail: {
                                    label: 'Mail',
                                    v: row.c[3].v,
                                },
                                pasMAnterior: {
                                    label: `Pas Productivos Junio`,
                                    v: row.c[4].f,
                                },
                                pasM: {
                                    label: `Pas Productivos ${meses[dayjs().subtract(1, 'month').month()]}`,
                                    v: row.c[5].f,
                                },
                                incremento: {
                                    label: 'Incremento %',
                                    v: row.c[6].f,
                                },
                                ranking: {
                                    label: 'Ranking',
                                    v: row.c[7].v,
                                }
            
                            };
                            parsedData.push(newRowData);
                        }
                    })
                    return parsedData;
                };
                break;
            case "gestores":
                parseFAUsar = (jsonData) => {
                    const jsonTable = JSON.parse(jsonData).table
                    const parsedData = []
                    
                    const dayjs = require('dayjs');
                    jsonTable.rows.forEach( (row, i) => {
                        if (i > 1) {
                            const meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
                            const newRowData = {
                                region: {
                                    label: 'Región',
                                    v: row.c[0].v,
                                },
                                oficina: {
                                    label: 'Oficina Comercial',
                                    v: row.c[1].v,
                                },
                                idGestor: {
                                    label: 'Id Gestor',
                                    v: row.c[2].v,
                                },
                                nombre: {
                                    label: 'Nombre y apellido',
                                    v: row.c[3].v,
                                },
                                mail: {
                                    label: 'Mail',
                                    v: row.c[4].v,
                                },
                                pasMAnterior: {
                                    label: `Pas Productivos Junio`,
                                    v: row.c[5].f,
                                },
                                pasM: {
                                    label: `Pas Productivos ${meses[dayjs().subtract(1, 'month').month()]}`,
                                    v: row.c[6].f,
                                },
                                incremento: {
                                    label: 'Incremento %',
                                    v: row.c[7].f,
                                },
                                ranking: {
                                    label: 'Ranking',
                                    v: row.c[8].v,
                                }
            
                            };
                            parsedData.push(newRowData);
                        }
                    })
                    return parsedData;
                };
                break;
            case "administrativos":
                parseFAUsar = (jsonData) => {
                    const jsonTable = JSON.parse(jsonData).table
                    const parsedData = []
                    jsonTable.rows.forEach( (row, i) => {
                        if (i > 1) {
                            const newRowData = {
                                region: {
                                    label: 'Región',
                                    v: row.c[0].v,
                                },
                                oficina: {
                                    label: 'Oficina Comercial',
                                    v: row.c[1].v,
                                },
                                codigo: {
                                    label: 'Código',
                                    v: row.c[2].v,
                                },
                                nombre: {
                                    label: 'Nombre y apellido',
                                    v: row.c[3].v,
                                },
                                mail: {
                                    label: 'Mail',
                                    v: row.c[4].v,
                                },
                                cartera: {
                                    label: 'Mantiene o crece Cartera?',
                                    v: row.c[5].v
                                },
                                ventaN: {
                                    label: 'Venta nueva',
                                    v: row.c[6].v
                                },
                                ranking: {
                                    label: 'Ranking',
                                    v: row.c[7].v,
                                }
            
                            };
                            parsedData.push(newRowData);
                        }
                    })
                    return parsedData;
                };
                break;
            case "delegados":
                parseFAUsar = (jsonData) => {
                    const jsonTable = JSON.parse(jsonData).table
                    const parsedData = []
                    
                    jsonTable.rows.forEach( (row, i) => {
                        if (i > 1) {
                            const newRowData = {
                                categoria: {
                                    label: 'Categoría',
                                    v: row.c[0].v,
                                },
                                segm: {
                                    label: 'Segm 24',
                                    v: row.c[1].v,
                                },
                                region: {
                                    label: 'Región',
                                    v: row.c[2].v,
                                },
                                oficina: {
                                    label: 'Oficina Comercial',
                                    v: row.c[3].v,
                                },
                                agencia: {
                                    label: 'Descripción Agencia',
                                    v: row.c[4].v,
                                },
                                agrupacion: {
                                    label: 'Descripción Agrupación Comercial',
                                    v: row.c[5].v,
                                },
                                mail: {
                                    label: 'Mail',
                                    v: row.c[6].v,
                                },
                                agrupacionCod: {
                                    label: 'Agrupacion Comercial',
                                    v: row.c[7].v,
                                },
                                incremento: {
                                    label: 'Incremento vigentes',
                                    v: row.c[8].f,
                                },
                                mix: {
                                    label: 'Mix venta nueva',
                                    v: row.c[9].f,
                                },
                                fase: {
                                    label: 'Fase',
                                    v: row.c[10].v,
                                },
                                ranking: {
                                    label: 'Ranking',
                                    v: row.c[11].v,
                                }
            
                            };
                            parsedData.push(newRowData);
                        }
                    })
                    return parsedData;
                };
                break;
            case "representacion":
                parseFAUsar = (jsonData) => {
                    const jsonTable = JSON.parse(jsonData).table
                    const parsedData = []
                    
                    jsonTable.rows.forEach( (row, i) => {
                        if (i > 1) {
                            const newRowData = {
                                categoria: {
                                    label: 'Categoría',
                                    v: row.c[0].v,
                                },
                                segm: {
                                    label: 'Segm 24',
                                    v: row.c[1].v,
                                },
                                region: {
                                    label: 'Región',
                                    v: row.c[2].v,
                                },
                                oficina: {
                                    label: 'Oficina Comercial',
                                    v: row.c[3].v,
                                },
                                agencia: {
                                    label: 'Descripción Agencia',
                                    v: row.c[4].v,
                                },
                                agrupacion: {
                                    label: 'Descripción Agrupación Comercial',
                                    v: row.c[5].v,
                                },
                                mail: {
                                    label: 'Mail',
                                    v: row.c[6].v,
                                },
                                agrupacionCod: {
                                    label: 'Agrupacion Comercial',
                                    v: row.c[7].v,
                                },
                                incremento: {
                                    label: 'Incremento vigentes',
                                    v: row.c[8].f,
                                },
                                mix: {
                                    label: 'Mix venta nueva',
                                    v: row.c[9].f,
                                },
                                fase: {
                                    label: 'Fase',
                                    v: row.c[10].v,
                                },
                                ranking: {
                                    label: 'Ranking',
                                    v: row.c[11].v,
                                }
            
                            };
                            parsedData.push(newRowData);
                        }
                    })
                    return parsedData;
                };
                break;
            case "pas":
                parseFAUsar = (jsonData) => {
                    const jsonTable = JSON.parse(jsonData).table
                    const parsedData = []
                    
                    jsonTable.rows.forEach( (row, i) => {
                        if (i > 1) {
                            const newRowData = {
                                categoria: {
                                    label: 'Categoría',
                                    v: row.c[0].v,
                                },
                                segm: {
                                    label: 'Segm 24',
                                    v: row.c[1].v,
                                },
                                region: {
                                    label: 'Región',
                                    v: row.c[2].v,
                                },
                                oficina: {
                                    label: 'Oficina Comercial',
                                    v: row.c[3].v,
                                },
                                agencia: {
                                    label: 'Descripción Agencia',
                                    v: row.c[4].v,
                                },
                                agrupacion: {
                                    label: 'Descripción Agrupación Comercial',
                                    v: row.c[5].v,
                                },
                                mail: {
                                    label: 'Mail',
                                    v: row.c[6].v,
                                },
                                agrupacionCod: {
                                    label: 'Agrupacion Comercial',
                                    v: row.c[7].v,
                                },
                                incremento: {
                                    label: 'Incremento vigentes',
                                    v: row.c[8].f,
                                },
                                mix: {
                                    label: 'Mix venta nueva',
                                    v: row.c[9].f,
                                },
                                fase: {
                                    label: 'Fase',
                                    v: row.c[10].v,
                                },
                                ranking: {
                                    label: 'Ranking',
                                    v: row.c[11].v,
                                }
            
                            };
                            parsedData.push(newRowData);
                        }
                    })
                    return parsedData;
                };
                break;
        
            default:
                break;
        }
        return parseFAUsar;
    }, [])
    useEffect(() => {
        setIsDataLoaded(false);
        const docRef = doc(database, "usuarios", auth.currentUser.uid)
        getDoc(docRef)
        .then(snap => {
            if (snap.exists() && snap.data().role) {
                const data = snap.data()
                setRole(data.role);
            }
        })
        .then(() => setIsDataLoaded(true))
        .catch(err => console.log(err))
    }, [copa, role]);
    useEffect( () => {
        if (isDataLoaded) {
        if (role === "gerentes" || role === "gestores" || role === "administrativos") {
            const copaAux = "productividad"
            setCopa(copaAux)
            getFromSheets(copaAux, "gerentes", setDataRowsLists1,setIsListLoaded1)
            getFromSheets(copaAux, "gestores", setDataRowsLists2,setIsListLoaded2)
            getFromSheets(copaAux, "administrativos", setDataRowsLists3,setIsListLoaded3)

            getDownloadSrc(copaAux, "gerentes", setDownloadSrc1,setIsDownloadLoaded1)
            getDownloadSrc(copaAux, "gestores", setDownloadSrc2,setIsDownloadLoaded2)
            getDownloadSrc(copaAux, "administrativos", setDownloadSrc3,setIsDownloadLoaded3)
        } else {
            const copaAux = "segmentacion"
            setCopa(copaAux)
            getFromSheets(copaAux, "delegados", setDataRowsLists1,setIsListLoaded1)
            getFromSheets(copaAux, "representacion", setDataRowsLists2,setIsListLoaded2)
            getFromSheets(copaAux, "pas", setDataRowsLists3,setIsListLoaded3)

            getDownloadSrc(copaAux, "delegados", setDownloadSrc1,setIsDownloadLoaded1)
            getDownloadSrc(copaAux, "representacion", setDownloadSrc2,setIsDownloadLoaded2)
            getDownloadSrc(copaAux, "pas", setDownloadSrc3,setIsDownloadLoaded3)
        }
        // console.log(copa)
        // console.log(role)
    }
    }, [isDataLoaded, role])

    const contextValue = useMemo(() => ({
        isDataLoaded,
        isListLoaded1,
        isListLoaded2,
        isListLoaded3,
        dataRowsLists1,
        dataRowsLists2,
        dataRowsLists3,

        isdownloadLoaded1,
        isdownloadLoaded2,
        isdownloadLoaded3,
        downloadSrc1,
        downloadSrc2,
        downloadSrc3,
        role,
        copa,
        parseFContext,
    }), [isDataLoaded,
        isListLoaded1,
        isListLoaded2,
        isListLoaded3,
        dataRowsLists1,
        dataRowsLists2,
        dataRowsLists3,

        isdownloadLoaded1,
        isdownloadLoaded2,
        isdownloadLoaded3,
        downloadSrc1,
        downloadSrc2,
        downloadSrc3,
        role,
        copa,
        parseFContext,])
    
    return(
        <Context.Provider 
            value={contextValue}>
            {children}
        </Context.Provider>
    )
}