import React from 'react';
import XIconController from '../XIcon/XIconController';
import SectionTitle from './SectionTitle';

const SectionTitleController = ({colorTitle, colorX, textContent, itemId}) => {
    return ( 
        <div className='section-title-container'>
            <XIconController color={colorX} />
            <XIconController color={colorX} />
            <XIconController color={colorX} />
            <SectionTitle color={colorTitle} text={textContent} itemId={itemId} />
            <XIconController color={colorX} />
            <XIconController color={colorX} />
            <XIconController color={colorX} />
        </div> 
    );
}
 
export default SectionTitleController;