import React from 'react';

const Banner = ({imgDL, imgD, imgM, alt}) => {
    return ( 
        <>
        <img className="banner-img desktop-l" src={imgDL ? imgDL : imgD} alt={alt ? alt : ""} style={{display: "none"}} />
        <img className="banner-img desktop" src={imgD} alt={alt ? alt : ""} />
        <img className="banner-img mobile" src={imgM ? imgM : imgD} alt={alt ? alt : ""} style={{display: "none"}} />
        </>
     );
}
 
export default Banner;