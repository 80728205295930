import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import TusDatos from '../TusDatos/TusDatos';
import Bases from '../Bases/Bases';
import Ranking from '../Ranking/Ranking';
import Premios from '../Premios/Premios';
import LoginController from '../../components/LogIn/LoginController';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { DataProvider } from '../../Context/userdata';
import ChangePasswordController from '../../components/ChangePassword/ChangePasswordController';
import ResetPasswordController from '../../components/ResetPassword/ResetPasswordController';

const Main = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUserLogged, setIsUserLogged] = useState(false);
    onAuthStateChanged( auth, user => {
        if (user) {
            setIsUserLogged(true)
        } else {
            setIsUserLogged(false)
        }
    })
    useEffect(() => {
        if (isUserLogged) {
          const pathsLogged = [
            "/change-password/productividad",
            "/change-password/segmentacion",
          ]
          if (!pathsLogged.includes(location.pathname)) {
            navigate("/")
          }
        } else {
          const paths = [
            "/reset-password/productividad",
            "/reset-password/segmentacion",
            "/login/productividad",
            "/login/segmentacion",
          ]
          if (!paths.includes(location.pathname)) {
            navigate("/login/segmentacion")
          }
      }
    }, [isUserLogged]);
    return ( 
        <Routes>
          <Route 
            path='/'
            element={ isUserLogged ? <DataProvider>
              <TusDatos />
              <Bases />
              <Ranking />
              <Premios />
            </DataProvider> : <></>}
          />
          <Route path='/login'>
            <Route 
              path=':copa'
              element={<LoginController />}
            />
          </Route>
          <Route path='/change-password'>
            <Route 
              path=':copa'
              element={<ChangePasswordController />}
            />
          </Route>
          <Route path='/reset-password'>
            <Route 
              path=':copa'
              element={<ResetPasswordController />}
            />
          </Route>
        </Routes>
     );
}
 
export default Main;