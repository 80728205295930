import React from 'react';
import Subtitle from './Subtitle';

const SubtitleController = ({color, text}) => {
    return ( 
        <div className='subtitle-container'>
            <Subtitle color={color} text={text} />
        </div>
     );
}
 
export default SubtitleController;