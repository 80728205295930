import React from 'react';
import img1 from '../../imgs/footer/Footer-01v2.png';
import img2 from '../../imgs/footer/Footer-02v2.png';
import img3 from '../../imgs/footer/Footer-03v2.png';
import img4 from '../../imgs/footer/Footer-04.png';

const Legal = () => {
    return ( 
        <div className="legal">
            <ul>
                <li><img src={img1} alt="Entidad autorizada por la Superintendencia de Seguros de la Nación" /></li>
                <li><img src={img2} alt="Registro N° 0699 MAPFRE Argentina Seguros de Vida S.A." /></li>
                <li><img src={img3} alt="Registro N°0213 MAPFRE Argentina Seguros S.A." /></li>
                <li><img src={img4} alt="SSN | Superintenencia de seguros de la Nación" /></li>
            </ul>
        </div>
     );
}
 
export default Legal;