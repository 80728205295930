import React from 'react';

const XIcon = () => {
    return ( 
        <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.19 10.19">
            <g id="Capa_1-2" data-name="Capa 1">
                <path fill="currentColor" d="M8.55,.28l-3.46,3.46L1.64,.28C1.26-.09,.65-.09,.28,.28H.28C-.09,.65-.09,1.26,.28,1.64l3.46,3.46L.28,8.55c-.37,.37-.37,.98,0,1.35H.28c.37,.37,.98,.37,1.35,0l3.46-3.46,3.46,3.46c.37,.37,.98,.37,1.35,0h0c.37-.37,.37-.98,0-1.35l-3.46-3.46,3.46-3.46c.37-.37,.37-.98,0-1.35h0c-.37-.37-.98-.37-1.35,0Z"/>
            </g>
        </svg>
     );
}
 
export default XIcon;