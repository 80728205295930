import React from 'react';
import XIconController from '../XIcon/XIconController';

const Modal = ({ contenido, close }) => {
    return ( 
        <div className='modal'>
            <button onClick={close}><XIconController color={"#ffffff"} /></button>
            <p>{contenido}</p>
        </div>
    );
}
 
export default Modal;